import React from "react"
import Anchor from "../../core/components/anchor"
import Banner from "../../client/compositions/banner"
import Block from "../../core/components/block"
import Button from "../../client/compositions/button"
import RelatedProducts from "../../client/compositions/related-products"
import Decoration from "../../client/compositions/decoration"
import DecorationFluid from "../../client/compositions/decoration-fluid"
import Navigation from "../../core/components/navigation"
import Overlap from "../../client/compositions/overlap"
import Row from "../../core/components/row"
import Slider from "../../core/components/slider"
import { PortfolioTaglineMenu } from "../../client/compositions/tagline"
import { quickMenu } from "../../client/elements/menus"

/**
 * Project: Windsor Page
 * @see https://zpl.io/bzgYOWl
 */
function ProjectWindsorPage() {
  return (
    <article
      id="page-portfolio-windsor"
      className="page-content portfolio-page light-quick-menu"
    >
      {quickMenu}

      <Banner
        title={
          <>
            <sup aria-hidden={true} role="presentation">
              Private Residence
            </sup>
            <div>Windsor</div>
          </>
        }
        content={<>Vero Beach, FL</>}
        className="flipped portfolio-banner"
      >
        <DecorationFluid image="portfolio-windsor/banner.jpg" />
      </Banner>

      <Row id="summary" className="content-plus-media flipped">
        <Block className="block-content dropped">
          <div className="content">
            <dl className="details">
              <div className="detail">
                <dt className="title">General Contractor</dt>
                <dd className="content">RCL Development (Vero Beach, FL)</dd>
              </div>
              <div className="detail">
                <dt className="title">Architect</dt>
                <dd className="content">
                  C.E. Block Architect (Vero Beach, FL)
                </dd>
              </div>
              <div className="detail">
                <dt className="title">F&B Consultant</dt>
                <dd className="content">
                  Complete Restaurant Equipment & Supplies
                </dd>
              </div>
              <div className="detail">
                <dt className="title">Bottle Capacity</dt>
                <dd className="content">36,596</dd>
              </div>
            </dl>
            <p>
              Astonishing capacity transcends a minimal footprint to provide
              near-limitless choice in wine.
            </p>
          </div>
        </Block>
        <Block className="block-media">
          <DecorationFluid image="/portfolio-windsor/content-01.medium.jpg" />
        </Block>
      </Row>

      <Row id="gallery-one" className="media-plus-media">
        <Block className="block-media">
          <DecorationFluid image="/portfolio-windsor/content-02.medium.jpg" />
        </Block>
        <Block className="block-media">
          <DecorationFluid image="/portfolio-windsor/content-03.medium.jpg" />
        </Block>
      </Row>

      <Row id="features" className="content-plus-media">
        <Block className="block-content dropped">
          <h4 className="title">Features</h4>
          <div className="content">
            <ul className="features">
              <li className="feature">
                Custom 10’ tall triple/triple inspire floor-to-ceiling racks.
              </li>
              <li className="feature">
                Custom 10’ tall single spiral inspire floor-to-ceiling wine
                racks.
              </li>
              <li className="feature">
                Custom 10’ tall wall-mounted triple and single bracket sets.
              </li>
              <li className="feature">All brushed stainless steel finished.</li>
            </ul>
          </div>
        </Block>
        <Block className="block-media">
          <DecorationFluid image="/portfolio-windsor/content-04.medium.jpg" />
        </Block>
      </Row>

      <Row id="gallery-two" className="single-media">
        <Block className="block-media">
          <Decoration image="/portfolio-windsor/content-05.jpg" />
        </Block>
      </Row>

      <RelatedProducts
        id="related-products"
        products={[
          {
            slug: "inspire-wall-mounted",
            title: "Inspire Collection",
            url: "products/inspire/wall-mounted/",
          },
        ]}
      />

      <PortfolioTaglineMenu portfolioSlug="portfolio-windsor" />
    </article>
  )
}

export default ProjectWindsorPage
